import { GtmDataLayerItem } from "../modules/base";

/** Pushes an event to the GTM datalayer, used to trigger GTM tags */
const pushToDataLayer = (event: GtmDataLayerItem) => {
  window.dataLayer.push(event);
};

declare global {
  interface Window {
    addConsentListener: (callback: () => void) => void;
  }
}

// In GTM we're only using these consent types
type gtmUserConsent = {
  adConsentGranted: boolean;
  analyticsConsentGranted: boolean;
  adUserDataConsentGranted: boolean;
  adPersonalizationConsentGranted: boolean;
};

const consentListeners: Array<(consent: gtmUserConsent) => void> = [];

// This function is called from the consent custom template in Google Tag Manager
// Which passes it a callback function, defined in the template, that updates consent state in GTM
window.addConsentListener = (callback) => {
  consentListeners.push(callback);
};

// Called when user consents to cookies
const onConsentChange = (consent: gtmUserConsent) => {
  consentListeners.forEach((callback) => callback(consent));
};

export const consentToAllInGtm = () => {
  // Update GTM consent state with all consent types true
  onConsentChange({
    adConsentGranted: true,
    analyticsConsentGranted: true,
    adUserDataConsentGranted: true,
    adPersonalizationConsentGranted: true,
  });
  // Push event to GTM to trigger tags that were previously blocked due to no consent
  // They should now run as consent was given
  pushToDataLayer({ event: "cookie_consent_granted" });
};

type GtmTrackType = "gtm-link-click" | "gtm-cta-click" | "gtm-button-click";

export type GtmTrackingConfig = {
  gtmTrack: GtmTrackType;
  gaCategory?: string;
  gaCtaType?: string;
  gaPageType?: string;
  gaPosition?: string;
  gaAction?: string;
  gaLabel?: string;
};

export default pushToDataLayer;
